import { useQuery } from '@tanstack/vue-query';

export const useMerchantsQuery = () => {
	const { $api } = useNuxtApp();

	const query = useQuery({
		queryKey: ['merchants'],
		queryFn: () => $api.GET('/merchants')
			.then(unwrapResponse)
			.then((data) => data?.toSorted(sortAlphabeticallyBy('name'))),

	});

	return query;
};
